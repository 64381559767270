import { createRouter, createWebHistory } from "vue-router";
import {
  titleFormatter,
  updateMetaTags,
  authenticateUser,
} from "@/etc/utils/helpers";
import { useAffiliateStore } from "@/stores/affiliate";
import { useAuthUserStore } from "@/stores/authUser";

function updateCanonicalLink(document, canonicalUrl) {
  let link = document.querySelector('link[rel="canonical"]');
  if (!link) {
    link = document.createElement("link");
    link.setAttribute("rel", "canonical");
    document.head.appendChild(link);
  }
  link.setAttribute("href", canonicalUrl);
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../pages/HomePage/HomePage.vue"),
    meta: {
      title: "Improving Health & Wellness | Treatments & Supplements",
      fullTitle: true, // don't append the site name to the title
    },
  },
  // get started
  {
    path: "/get-started",
    name: "Get Started",
    component: () =>
      import("../pages/GetStarted/GetStartedPage/GetStarted.vue"),
    meta: {
      title: "Find a Healthier, Happier You with Ways2Well | Get Started",
      fullTitle: true,
      description:
        "Start your journey to a healthier, happier you with personalized health and wellness treatments from Ways2Well. Get started today.",
    },
  },
  {
    path: "/get-started/comprehensive-blood-work",
    name: "Get Started - Comprehensive Blood Work",
    component: () =>
      import(
        "../pages/GetStarted/ComprehensiveBloodwork/ComprehensiveBloodwork.vue"
      ),
    meta: {
      title: "Find a Healthier, Happier You with Ways2Well | Get Started",
      fullTitle: true,
      description:
        "Get comprehensive blood work done at Ways2Well. Understand your health better with detailed blood analysis and expert insights.",
    },
  },
  {
    path: "/get-started/checkout/:id",
    name: "New Checkout Page",
    component: () => import("../pages/GetStarted/Checkout/Checkout.vue"),
    meta: {
      title: "Find a Healthier, Happier You with Ways2Well | Get Started",
      fullTitle: true,
      robots: "noindex",
      description:
        "Complete your purchase for Ways2Well services. Secure checkout for your selected health and wellness treatments.",
    },
  },
  {
    path: "/get-started/checkout/:id/success",
    name: "Checkout Page - Success",
    component: () => import("../pages/SuccessPage/SuccessPage.vue"),
    meta: {
      robots: "noindex",
    },
  },
  // Path for non-purchase registration
  {
    path: "/register",
    name: "Register",
    component: () => import("../pages/GetStarted/NonPurchaseRegister.vue"),
    meta: {
      title: "Find a Healthier, Happier You with Ways2Well | Register",
      fullTitle: true,
    },
  },
  // schedule provider is one off appointment maker for providers
  {
    path: "/schedule-provider",
    name: "Schedule With Provider",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule-consultation",
    name: "Schedule Consultation",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  // {
  //   path: "/schedule-bpc-157",
  //   name: "Schedule BPC-157",
  //   component: () => import("../pages/Schedule/SchedulePage.vue"),
  // },
  {
    path: "/schedule-ghk-cu",
    name: "Schedule GHK-Cu",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule-cell-therapy-initial-intake",
    name: "Schedule Cell Therapy Initial Intake",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule-eeg-consultation",
    name: "Schedule EEG Consultation",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule-semaglutide",
    name: "Schedule Semaglutide",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule-cell",
    name: "Schedule Cell",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule-gold",
    name: "Schedule Gold Lab Review",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule-silver",
    name: "Schedule Silver Lab Review",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule-bronze",
    name: "Schedule Bronze Lab Review",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule-testing",
    name: "GI Map & Allergy Test",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule-iv-houston",
    name: "IV Therapy Houston",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule-iv-austin",
    name: "IV Therapy Austin",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule",
    name: "Schedule Appointment",
    component: () => import("../pages/Schedule/SchedulePage.vue"),
  },
  {
    path: "/schedule/success",
    name: "Schedule Success",
    component: () => import("../pages/Schedule/ScheduleSuccess.vue"),
  },
  {
    path: "/schedule/success/complete",
    name: "Schedule Complete",
    component: () => import("../pages/Schedule/ScheduleSuccess.vue"),
  },
  {
    path: "/company/about-us",
    name: "About Us",
    component: () => import("../pages/AboutPages/AboutUs/AboutUs.vue"),
    meta: {
      description:
        "Learn about Ways2Well, our mission, and the dedicated team behind our health and wellness services. Discover how we're committed to improving your well-being.",
    },
  },
  // {
  //   path: "/company/meet-the-team",
  //   name: "Team",
  //   component: () => import("../pages/AboutPages/MeetTheTeam/MeetTheTeam.vue"),
  //   meta: {
  //     title: "Meet the Team",
  //     description:
  //       "Meet the experienced and compassionate team at Ways2Well. Learn about the professionals dedicated to providing exceptional health and wellness services.",
  //   },
  // },
  // {
  //   path: "/company/meet-our-providers",
  //   name: "Providers",
  //   component: () =>
  //     import("../pages/AboutPages/MeetOurProviders/MeetOurProviders.vue"),
  //   meta: {
  //     title: "Meet Our Providers",
  //     description:
  //       "Meet the dedicated providers at Ways2Well. Learn about the experts committed to delivering personalized health and wellness solutions.",
  //   },
  // },
  {
    path: "/company/testimonials",
    name: "Testimonials",
    component: () =>
      import("../pages/AboutPages/Testimonials/Testimonials.vue"),
  },
  {
    path: "/jre",
    name: "Cited References From The Joe Rogan Experience",
    component: () => import("../pages/LinksPage/LinksPage.vue"),
    meta: {
      title: "Health Articles from the Joe Rogan Podcast",
      description:
        "Explore the collaboration between Ways2Well and the Joe Rogan Experience. Discover innovative health solutions discussed on the popular podcast.",
    },
  },
  {
    path: "/support/faq",
    name: "Frequently Asked Questions",
    component: () => import("../pages/FAQPage/FAQPage.vue"),
    meta: {
      title: "Questions? We Have Answers! FAQ",
      description:
        "Find answers to frequently asked questions about Ways2Well's services, treatments, and more. Get the information you need to make informed health decisions.",
    },
  },
  {
    path: "/support/quick-links",
    name: "Quick Links",
    component: () => import("../pages/QuickLinksPage/QuickLinksPage.vue"),
    meta: {
      title: "Quick Links",
      description:
        "Access essential resources and information quickly with Ways2Well's quick links. Find what you need to improve your health and wellness.",
    },
  },
  {
    path: "/company/locations",
    name: "Locations",
    component: () => import("../pages/LocationsPage/LocationsPage.vue"),
    meta: {
      title: "Locations",
      description:
        "Find a Ways2Well location near you. Explore our locations to access personalized health and wellness services.",
    },
  },
  {
    path: "/support/contact-us",
    name: "Contact Us",
    component: () => import("../pages/ContactUsPage/ContactUsPage.vue"),
    meta: {
      title: "Get in Touch with Our Wellness Team",
      description:
        "Get in touch with Ways2Well for any inquiries or support. Contact our team for assistance with our health and wellness services.",
    },
  },
  {
    path: "/support/inquiry",
    name: "Inquiry",
    component: () => import("../pages/InquiryPage/InquiryPage.vue"),
    meta: {
      title: "Get more information from Our Wellness Team",
      robots: "noindex",
    },
  },
  {
    path: "/product/:id",
    name: "Product page",
    component: () => import("../pages/ProductPage/ProductPage.vue"),
  },
  {
    path: "/covered-states",
    name: "Covered States",
    component: () => import("../pages/CoveredStates/CoveredStates.vue"),
    meta: {
      description:
        "Check if Ways2Well services are available in your state. Learn about the states where we provide health and wellness treatments.",
    },
  },
  // questionnaire
  {
    path: "/patient-portal-reminder",
    name: "Patient Portal Reminder",
    component: () => import("../pages/Flow/PatientPortalReminder.vue"),
  },
  {
    path: "/questionnaire",
    name: "Questionnaire Welcome",
    component: () =>
      import(
        "../pages/QuestionnairePage/QuestionnaireWelcome/QuestionnaireWelcome.vue"
      ),
  },
  {
    path: "/questionnaire/step",
    name: "Questionnaire",
    component: () => import("../pages/QuestionnairePage/QuestionnairePage.vue"),
    children: [
      {
        path: ":id",
        component: () =>
          import(
            "../pages/QuestionnairePage/QuestionnaireStepNew/QuestionnaireStepNew.vue"
          ),
      },
    ],
  },
  // stem cells pages
  {
    path: "/get-started/stemcells/tell-us-more",
    redirect: "/product/stem-cell-therapy-consultation",
    // component: () =>
    //   import("../pages/StemCellTherapy/StemCellTherapy-Selection.vue"),
    // meta: {
    //   title: "Stem Cell Therapy",
    // },
  },
  {
    path: "/get-started/stemcells/register",
    redirect: "/product/stem-cell-therapy-consultation",
    // component: () =>
    //   import("../pages/StemCellTherapy/StemCellTherapy-Register.vue"),
    // meta: {
    //   title: "Stem Cell Therapy Register",
    // },
  },
  // informational pages
  {
    path: "/mens-health",
    component: () => import("../pages/MensHealth/MensHealth.vue"),
    meta: {
      title: "Men's Health | Preventative Care",
      robots: "noindex",
    },
  },

  {
    path: "/health/hormone-optimization",
    component: () =>
      import(
        "../pages/MensHealth/MenHormonesOptimization/MenHormonesOptimization.vue"
      ),
    meta: {
      title: "Men's Health | Total Hormone Optimization",
      robots: "noindex",
    },
  },
  {
    path: "/mens-health/non-surgical-hair-restoration",
    component: () => import("../pages/MensHealth/MenHairLoss/MenHairLoss.vue"),
    meta: {
      title: "Men's Health | Hair Loss Treatment",
      robots: "noindex",
    },
  },
  {
    path: "/womens-health",
    component: () => import("../pages/WomensHealth/WomensHealth.vue"),
    meta: {
      title: "Women's Health | Preventative Care",
    },
  },
  {
    path: "/health/womens-hormone-therapy",
    component: () =>
      import(
        "../pages/WomensHealth/WomenHormonesOptimization/WomenHormonesOptimization.vue"
      ),
    meta: {
      title: "Women's Health | Total Hormone Optimization",
    },
  },
  {
    path: "/womens-health/non-surgical-hair-restoration",
    component: () =>
      import("../pages/WomensHealth/WomenHairLoss/WomenHairLoss.vue"),
    meta: {
      title: "Women's Health | Hair Loss Treatment",
    },
  },
  {
    path: "/health/beyond-stem-cells",
    component: () =>
      import("../pages/InformationalPages/CellTherapy/CellTherapy.vue"),
    meta: {
      title: "Regenerative Therapy | Chronic Pain Treatment",
    },
  },
  {
    path: "/health/skin-rejuvenating-treatments",
    component: () =>
      import(
        "../pages/InformationalPages/SkinRejuvenatingTreatments/SkinRejuvenatingTreatments.vue"
      ),
    meta: {
      title: "Skin Treatments: Microneedling, Supplements",
    },
  },
  {
    path: "/health/hair-loss",
    component: () =>
      import("../pages/InformationalPages/HairLoss/HairLoss.vue"),
    meta: {
      title: "Hair Loss Treatment",
      description:
        "Discover effective solutions for hair loss at Ways2Well. Explore our treatments designed to promote hair growth and restore your confidence.",
    },
  },
  {
    path: "/health/iv-hydration",
    component: () =>
      import(
        "../pages/InformationalPages/IVHydration/IVHydrationLanding/IVHydrationLanding.vue"
      ),
    meta: {
      title: "IV Therapy for Hydration and Overall Wellness",
      description:
        "Experience the benefits of IV hydration therapy at Ways2Well. Learn how our treatments can help you stay hydrated, energized, and healthy.",
    },
  },
  {
    path: "/health/iv-hydration/select-location",
    component: () =>
      import(
        "../pages/InformationalPages/IVHydration/SelectLocation/SelectLocation.vue"
      ),
    meta: {
      robots: "noindex",
    },
  },
  {
    path: "/health/iv-hydration/select-treatment",
    component: () =>
      import(
        "../pages/InformationalPages/IVHydration/SelectTreatment/SelectTreatment.vue"
      ),
    name: "SelectTreatment",
  },
  {
    path: "/health/iv-hydration/register",
    component: () => import("../pages/GetStarted/IVRegister.vue"),
    name: "IVRegister",
  },
  {
    path: "/health/iv-hydration/questionnaire",
    component: () =>
      import(
        "../pages/InformationalPages/IVHydration/IVQuestionnaire/IVQuestionnaire.vue"
      ),
    name: "IVQuestionnaire",
  },
  {
    path: "/health/iv-hydration/product/:id",
    component: () =>
      import("../pages/InformationalPages/IVHydration/IVProduct/IVProduct.vue"),
    name: "IVProduct",
  },
  {
    path: "/health/peptide-therapy",
    component: () =>
      import("../pages/InformationalPages/PeptideTherapy/PeptideTherapy.vue"),
    meta: {
      title: "Elevating Overall Wellness with Peptide Therapy",
      description:
        "Explore the benefits of peptide therapy at Ways2Well. Learn how our treatments can help enhance your body's natural healing processes.",
    },
  },
  // blog pages
  {
    path: "/blog",
    component: () => import("../pages/Blog/BlogHome/BlogHome.vue"),
    meta: {
      title: "Ways2Well Blog",
      description:
        "Stay informed with the latest health and wellness insights on the Ways2Well blog. Read articles, tips, and news to support your healthy lifestyle.",
    },
  },
  {
    path: "/blog/:slug",
    component: () => import("../pages/Blog/BlogPost/BlogPost.vue"),
  },
  {
    path: "/blog/:pathMatch(.*)*",
    name: "NotFound",
    meta: {
      robots: "noindex",
    },
    component: () => import("../pages/Blog/NotFound/NotFound.vue"),
  },
  {
    path: "/blog/undefined",
    name: "NotFound",
    meta: {
      robots: "noindex",
    },
    component: () => import("../pages/Blog/NotFound/NotFound.vue"),
  },
  {
    path: "/shop/products",
    component: () => import("../pages/ShopPages/ShopPage/ShopPage.vue"),
    meta: {
      robots: "noindex",
      description:
        "Shop a wide range of health and wellness products at Ways2Well. Find supplements, skincare, and more to support your health journey.",
    },
  },
  // Terms of Service
  {
    path: "/terms",
    component: () => import("../pages/WpPage/WpPage.vue"),
    props: { page: "terms-of-service" },
  },
  // Credit Policy
  {
    path: "/credit-policy",
    component: () => import("../pages/WpPage/WpPage.vue"),
    props: { page: "credit-policy" },
    meta: {
      description:
        "Read the credit policy for Ways2Well. Understand our terms regarding credit usage and payment for services.",
    },
  },
  // Privacy Policy
  {
    path: "/privacy-policy",
    component: () => import("../pages/WpPage/WpPage.vue"),
    props: { page: "privacy-policy" },
    meta: {
      description:
        "Understand how Ways2Well protects your privacy. Read our privacy policy to learn about data collection, usage, and your rights.",
    },
  },
  // Self Payment of Services
  {
    path: "/self-payment-of-services",
    component: () => import("../pages/WpPage/WpPage.vue"),
    props: { page: "self-payment-of-services" },
    meta: {
      robots: "noindex",
      description:
        "Understand the self-payment policy for Ways2Well services. Learn about our payment terms and conditions for self-paying clients.",
    },
  },
  // Allen Chat Bot Policy
  {
    path: "/allen-chatbot-policy",
    component: () => import("../pages/WpPage/WpPage.vue"),
    props: { page: "allen-chatbot-policy" },
  },

  // affiliate
  {
    path: "/referral",
    name: "ReferAFriend",
    component: () => import("../pages/Affiliate/Dashboard.vue"),
    meta: {
      title: "Refer",
      description:
        "Refer a friend to Ways2Well and help them experience optimal health. Learn about our referral program and how you can benefit.",
    },
    beforeEnter: async function handleAffiliateReferedCode(to) {
      const affiliateStore = useAffiliateStore();

      if (to.query.code) {
        affiliateStore.referrerCode = to.query.code;
        return {
          path: to.query.redirect ? decodeURIComponent(to.query.redirect) : "/",
          // remove the query parameter
          query: {},
          hash: to.hash,
        };
      }
      // otherwise, business as usual
    },
  },
  {
    path: "/referral/logout",
    name: "referralLogout",
    redirect: () => {
      const affiliateStore = useAffiliateStore();
      const store = useAuthUserStore();
      setTimeout(() => {
        store.setAuthUserData(null);
        affiliateStore.init();
      });
      return { name: "Home" };
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: () => {
      return { path: "/" };
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...routes,
    {
      path: "/auth-user",
      name: "Auth User",
      beforeEnter: authenticateUser,
    },
  ],

  // always scroll to top
  scrollBehavior() {
    return { top: 0 };
  },
});

// inject page title
const DEFAULT_TITLE = "Ways2Well";
router.beforeEach((to, from, next) => {
  if (to.meta.fullTitle) {
    document.title = to.meta.title;
  } else if (to.meta.title) {
    document.title = `${to.meta.title} | Ways2Well`;
  } else {
    document.title = DEFAULT_TITLE;
  }
  let title = "Ways2Well";
  const metaTags = [];
  const { meta } = to;

  if (meta.title) {
    title = meta.fullTitle !== true ? titleFormatter(meta.title) : meta.title;

    document.title = title;
    // metaTags.push({ property: "og:title", content: title });
    // metaTags.push({ name: "twitter:title", content: title });
    // metaTags.push({ name: "title", content: title });

    // console.log("title", title);
  }

  if (meta.description) {
    metaTags.push({ name: "description", content: meta.description });
    metaTags.push({ property: "og:description", content: meta.description });
    metaTags.push({ name: "twitter:description", content: meta.description });
  }

  if (meta.robots) {
    // console.log("robots", meta.robots);
    metaTags.push({ name: "robots", content: meta.robots });
  }

  updateMetaTags(document, metaTags);
  // console.log("metaTags", metaTags);

  const canonicalUrl = window.location.origin + to.fullPath;
  updateCanonicalLink(document, canonicalUrl);

  next();
});

export { routes };
export default router;
